import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

// Lazy load the Terms component
const Terms = loadable(() => import("../components/Contract/T&C/Content.jsx"))

export default function TermsPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/terms-of-use" />
      </Helmet>
      <Terms />
    </Layout>
  )
}
